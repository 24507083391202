* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  background-color: #F0F1F2;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.entry {
  width: 80vw;
  padding: 3rem;
  margin: 1.5rem auto;
  background-color: white;
  border-radius: 1.5rem;
}

.input-form {
  padding: 3rem 1rem;
}

.input {
  /* reset */
  display: inline-block;
  margin: 0;
  border: 0;
  padding: 0;
  width: auto;
  white-space: normal;
  line-height: inherit;
  background: none;

  /* Browsers have different default form fonts */
  color: inherit;
  font-size: inherit;
  font-family: inherit;

  /* our styles */
  height: 45px;
  width: 100%;
  padding-left: 1rem;
  background-color: white;
  border: solid 1px #D8D8D8;
  margin-top: 1.5rem;
  color: black;
}

.input::placeholder {
  color: #9B9B9B;
  font-style: italic;
}

.button {
  /* reset */
  display: inline-block;
  margin: 0 auto;
  border: 0;
  padding: 0;
  width: auto;
  white-space: normal;
  line-height: inherit;
  background: none;

  /* Browsers have different default form fonts */
  color: inherit;
  font-size: inherit;
  font-family: inherit;

  /* our styles */
  display: block;
  height: 45px;
  width: 100%;
  margin-top: 1.5rem;
  background-color: #333F4B;
  cursor: pointer;
  color: white;
}

@media screen and (min-width: 768px) {
  .input {
    max-width: 450px;
  }

  .button {
    max-width: 180px;
  }
}

/* Typography */

@font-face {
  font-family: 'Baskerville';
  src: url(./fonts/Baskerville-SemiBold.ttf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'EmersonHelvetica';
  src: url(./fonts/Helvetica-Light.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'EmersonHelvetica';
  src: url(./fonts/Helvetica-Bold.ttf);
  font-weight: 500;
  font-style: normal;
}

body {
  color: #333333;
  font-family: 'EmersonHelvetica', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  letter-spacing: 1.5px;
}

.f-title, h1 {
  font-family: 'Baskerville', sans-serif;
  font-weight: bold;
  margin-bottom: 1rem;
  letter-spacing: initial;
}

.f-label {
  font-family: 'EmersonHelvetica', sans-serif;
  text-transform: uppercase;
  margin-bottom: 1rem;
  letter-spacing: 6;
}

.f-extra-large, h1 { font-size: 2.2rem; line-height: 2.5rem; }
.f-large, h2 { font-size: 2.1rem; }
.f-medium, h3 { font-size: 1rem; }
.f-small { font-size: 0.8rem; }

@media screen and (min-width: 768px) {
  .f-extra-large { font-size: 2.2rem; }
  .f-large { font-size: 2rem; }
  .f-medium { font-size: 1.4rem; }
  .f-small { font-size: 0.8rem; }
}